<template>
  <section class="elv-accounts-total-position-container">
    <AccountScreening
      ref="totalPositionScreeningRef"
      tableType="TotalPosition"
      :exportText="$t('button.export')"
      @onChangeAccountFilter="onChangeDatetime"
    />
    <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.totalPosition" />
    <div class="elv-accounts-total-position-table-container">
      <ElvGridTable
        ref="elvGridTableRef"
        class="elv-accounts-total-position-table"
        :tableGridType="TableGridType.TOTAL_POSITION"
        :columnDefs="columnDefs"
        @onFetchTableList="getTotalPositionList"
      />
    </div>
    <LimitReachedDialog @onConfirmUpgradeProjectPlan="onConfirmUpgradeProjectPlan" />
    <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
  </section>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import { capitalize } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import { $t, transformI18n } from '@/i18n'
import { TableGridType } from '#/GlobalConfigTypes'
import { TotalPositionType } from '#/AccountsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import AccountScreening from '../components/Screening.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import ElvGridTable from '@/components/Base/Table/ElvGridTable.vue'
import LimitReachedDialog from '@/pages/Financials/components/LimitReachedDialog.vue'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'
import { type ValueFormatterFunc, ITooltipParams, ValueGetterParams, ValueFormatterParams } from 'ag-grid-enterprise'
import { exportProgressStatusType } from '@/config/global'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const elvGridTableRef = useTemplateRef('elvGridTableRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')
const totalPositionScreeningRef = useTemplateRef<InstanceType<typeof AccountScreening>>('totalPositionScreeningRef')

const tableLoading = ref(false)

const numberFormatter: ValueFormatterFunc = (params) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2
  })
  const number =
    params.value === null || params.value === '' || params.value === undefined ? '' : new Big(params.value).toString()

  return params.value === null || params.value === '' || params.value === undefined
    ? ''
    : formatter.format(new Big(number).toNumber())
}

const columnDefs = reactive([
  {
    headerName: t('common.platformType'),
    field: 'platformType',
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => {
      // eslint-disable-next-line no-use-before-define
      return transformI18n(platformType(params.data?.platformType ?? ''))
    },
    rowGroup: true,
    hide: true
  },
  {
    headerName: t('common.account'),
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => params.data?.entityAccount?.name,
    rowGroup: true
  },
  {
    headerName: t('common.accountType'),
    field: 'accountType',
    rowGroup: true,
    hide: true,
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => {
      return capitalize(params?.data?.accountType ?? '')
    }
  },
  {
    headerName: t('common.dateTime'),
    field: 'datetime',
    cellDataType: 'dateString',
    valueFormatter: (params: ValueFormatterParams<TotalPositionType>) => {
      return params.value ? dayjs(params.value).format('YYYY/MM/DD HH:mm:ss') : ''
    }
  },
  {
    headerName: t('totalPosition.provider'),
    field: 'provider',
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => params.data?.provider?.name
  },
  {
    headerName: t('totalPosition.category'),
    field: 'category',
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => {
      return capitalize(params?.data?.category ?? '')
    }
  },
  { headerName: t('totalPosition.asset'), field: 'currency' },
  {
    headerName: t('totalPosition.syncedAmount'),
    field: 'amount',
    cellDataType: 'number',
    tooltipValueGetter: (params: ITooltipParams<TotalPositionType>) => {
      return params.value || params.value === 0 ? formatNumber(params.value, 20) : ''
    },
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => {
      return !params?.node?.group ? new Big(params.data?.amount || 0).toNumber() : ''
    },
    valueFormatter: numberFormatter
  },
  {
    headerName: t('totalPosition.inUSDT'),
    field: 'inUSDT',
    aggFunc: 'sum',
    functionsReadOnly: true,
    enableValue: true,
    tooltipField: 'inUSDT',
    cellDataType: 'number',
    cellClass: 'is-aggregate-cell',
    tooltipValueGetter: (params: ITooltipParams<TotalPositionType>) => {
      return params.value || params.value === 0 ? formatNumber(params.value, 20) : ''
    },
    valueGetter: (params: ValueGetterParams<TotalPositionType>) => {
      return params.data?.inUSDT ? new Big(params.data?.inUSDT || 0).toNumber() : params.node?.aggData?.inUSDT
    },
    valueFormatter: (params: ValueFormatterParams<TotalPositionType>) => {
      return numberFormatter(params)
    }
  }
])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const platformType = (type: string) => {
  let text = ''
  switch (type) {
    case 'CHAIN':
      text = $t('common.onChain')
      break
    case 'EXCHANGE':
      text = $t('common.exchange')
      break
    case 'BANK':
      text = $t('common.bank')
      break
    case 'CUSTODY':
      text = $t('common.custody')
      break
    case 'PAYMENT':
      text = t('common.payment')
      break
    default:
      text = ''
      break
  }
  return text
}

const onConfirmUpgradeProjectPlan = () => {
  upgradeProjectPlanDialogRef.value?.changeUpgradeProjectDialogStatus()
}

const getTotalPositionList = async () => {
  try {
    tableLoading.value = true
    // 默认当天 00:00:00
    const datetime =
      totalPositionScreeningRef.value?.dateTimeValue ??
      dayjs().tz(entityStore.entityDetail?.timezone).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    accountStore.setRequestedList({ url: `/entity/${entityId.value}/totalPosition`, params: { datetime } })
    await accountStore.fetchTotalPositionList(entityId.value, { datetime })
    accountStore.setRequestedList({ url: `/entity/${entityId.value}/totalPosition`, params: { datetime } })
    if (!accountStore.totalPositionList.length) {
      elvGridTableRef.value?.gridApi?.showNoRowsOverlay()
      return
    }
    elvGridTableRef.value?.gridApi?.setGridOption('rowData', accountStore.totalPositionList)
  } catch (error: any) {
    console.log(error)
    accountStore.totalPositionList = []
    if (error?.code !== -1 && error.message !== 'canceled') {
      ElMessage.error(error.message)
    }
    elvGridTableRef.value?.gridApi?.setGridOption('rowData', [])
  } finally {
    tableLoading.value = false
  }
}

const onChangeDatetime = async () => {
  elvGridTableRef.value?.resetTableList()
}
</script>

<style lang="scss" scoped>
.elv-accounts-total-position-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-accounts-total-position-table-container {
    width: 100%;
    flex: 1;
    min-height: 0px;
    padding-bottom: 14px;
    position: relative;
  }

  :deep(.elv-accounts-total-position-table.elv-grid-theme-quartz-table) {
    .ag-row-group .ag-cell-wrapper.ag-cell-expandable {
      .ag-group-value {
        color: #0e1420;
        font-weight: 600;
      }
    }

    .ag-cell.is-number-cell {
      font-weight: 500;
      color: #0e0f11;

      &.is-aggregate-cell {
        font-weight: 600;
      }
    }
  }
}
</style>
