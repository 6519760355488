<template>
  <p>
    {{ props.params.value }} <span class="ag-group-child-count">{{ props.params?.node?.allChildrenCount }}</span>
  </p>
</template>

<script setup lang="ts">
import { type ICellRendererParams } from 'ag-grid-enterprise'

const props = defineProps<{
  params: ICellRendererParams
}>()
</script>

<style lang="scss" scoped></style>
