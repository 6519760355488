/* eslint-disable no-unused-vars */
import { type ColumnState, GridState } from 'ag-grid-enterprise'

export interface LanguagesType {
  zh: any
}

export interface ChainType {
  chainId: string
  logo: string
  name: string
  pro: number
  slug: string
}

export interface ExchangeType {
  exchangeId: string
  logo: string
  name: string
  pro: number
}

export interface BankType {
  bankId: string
  logo: string
  name_en: string
  name_zh: string
  slug: string
}

export enum PlatformEnum {
  CHAIN = 'CHAIN',
  EXCHANGE = 'EXCHANGE',
  BANK = 'BANK',
  PAYMENT = 'PAYMENT',
  CUSTODY = 'CUSTODY'
}

export interface PlatformType {
  platformId: string
  name: string
  logo: string
  type: PlatformEnum
  pro: number
}

export interface ProjectSubscribePlanType {
  introduce: Array<any>
  subscriptionPlans: Array<any>
}

export interface ConfigType {
  languages: LanguagesType
  platforms: PlatformType[]
  plan: ProjectSubscribePlanType
}

export enum TableGridType {
  TOTAL_POSITION = 'TOTAL_POSITION'
}
export interface LocalGridStateType {
  entityId: string
  tableType: TableGridType
  gridState: GridState
}
